import React from 'react';
import './css/style.css';
import { connect } from 'react-redux';
// import { MDBBtn } from "mdbreact";
import DataLoader from "./components/DataLoader";
import ToolBar from "./components/ToolBar";
import TopBar from "./components/TopBar";
import Main from "./components/Main";
import InstallInstructions from "./components/InstallInstructions";
// import { MDBCard, MDBCardTitle, MDBCardText } from "mdbreact";
import { loadSettings, loadMeters } from './actions';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";


class App extends React.Component {

    render = () => {

        return (
            <div className="h-100">
                <TopBar />
                <Router>
                    <Switch>
                        <Route path="/installed">
                            <DataLoader />
                            <ToolBar displaySettings={true} />
                            <Main />
                            {/* <Route exact path="/installed" component={Main} /> */}
                        </Route>
                        <Route path="/">
                            <ToolBar displaySettings={false} />
                            <InstallInstructions />
                        </Route>

                    </Switch>
                </Router>
            </div>
        );
    }
}

// export default App;
function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        loadSettings,
        loadMeters
    }
)(App);

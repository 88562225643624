import React from 'react';
import { connect } from 'react-redux';
// import { MDBModal, MDBModalBody } from "mdbreact";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCog } from '@fortawesome/free-solid-svg-icons'
import { openSettings, closeSettings } from '../actions';
import kokaLogo from '../img/koka.png';

class ToolBar extends React.Component {

    state = {
        modal: false,
        image: undefined
    }

    toggleSettings = () => {
        this.setState({
            settings: !this.state.settings
        });
    }

    render = () => {
        return (
            <div>
                <div className="footer fixed-bottom blue lighten-4 p-2 text-primary text-center">
                    <img src={kokaLogo} alt="" style={{height:'30px', marginBottom: '.35em'}} />
                    {/* <FontAwesomeIcon style={{position:'absolute', right: '.25em', color: 'rgba(0,0,0,0.25)'}} icon={faCog} size="2x" onClick={this.props.openSettings} /> */}
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        openSettings,
        closeSettings,
    }
)(ToolBar);

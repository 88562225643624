import React from 'react';
import { connect } from 'react-redux';
// import { MDBCard } from "mdbreact";
// import { MDBBtn } from "mdbreact";
import Settings from "./Settings";
import Scanner from "./Scanner";
import Intro from "./Intro";
import GeneralSettings from "./GeneralSettings";
import MeterSettings from "./MeterSettings";
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";

// import api from '../api';

class Main extends React.Component {

    render = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12" style={{paddingBottom: '30px'}}>
                        {/* <MDBCard className="info-color text-center p-2" style={{minHeight: '100px'}}>Scan</MDBCard> */}

                        {/* { this.props.settings.displayIntro ? <Intro/> : null } */}

                        <Router>
                            <Switch>

                                <Route exact path="/installed/">
                                    <Intro />
                                </Route>

                                <Route path="/installed/general/">
                                    <GeneralSettings />
                                </Route>

                                <Route path="/installed/meter/">
                                    <MeterSettings />
                                </Route>

                            </Switch>
                        </Router>
                    </div>
                </div>
                <Settings />
                <Scanner />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        "foo": "bar"
    }
)(Main);

import React from 'react';
import { connect } from 'react-redux';
// import { MDBCard } from "mdbreact";
import { MDBBtn } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { hideIntro } from '../actions'

import { Route } from 'react-router-dom'

class Intro extends React.Component {

    // Hide and do not show again
    hideIntro = () => {
        this.props.hideIntro();
    }

    // Hide but show again next time the app is opened
    passIntro = (history) => {
        history.push('/installed/general/');
    }

    render = () => {
        return (
            <div>
                <h4>Tervetuloa</h4>
                <p>Tervetuloa Hydrodigit-vesimittareiden käyttöönotto-ohjelmistoon.</p>
                <p>Tämän sovelluksen avulla kerätään jo asennetuista vesimittareista sarjanumerot ja muodostetaan luettelo. Luettelon perusteella järjestelmä tietää missä huoneistossa mikäkin mittari sijaitsee.</p>
                <p className="font-weight-bold">Luettelon muodostaminen vaatii pääsyn vesimittareiden luokse.</p>

                <Route render={({ history }) => (
                    <MDBBtn className="w-100 m-0" color="primary" onClick={() => { this.passIntro(history) } }>
                        Jatka
                        <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                    </MDBBtn>
                )} />

            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        hideIntro
    }
)(Intro);

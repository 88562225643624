import Dexie from 'dexie';

const dbName = 'web-app-demo';
const dbVersion = 1;

const db = new Dexie(dbName);
db.version(dbVersion).stores(
    {
        settings: '&key, value',
        meters: '++, *serialNumber, apartment, meterType',
    }
);

const loadSettings = async () => {
    const response = await db.settings.toArray();
    var d = {};
    for(let i in response) {
        d[response[i].key] = response[i].value;
    }
    return d;
}

const loadSetting = async (key) => {
    const response = await db.settings.get({key: key});
    if (response) {
        return response.value;
    }
    return response;
}

const deleteSetting = async (id) => await db.settings.delete(id);

const saveSetting = async (setting) => {
    return await db.settings.put(setting);
};

const saveSettings = async (settings) => {
    var d = [];
    for(let i in settings) {
        d.push({key: i, value: settings[i]});
    }
    return await db.settings.bulkPut(d);
};

const loadMeters = async () => {
    const response = await db.meters.toArray();
    return response;
}

const meterExistsBySerial = async (serialNumber) => {
    const existing = await db.meters.get({serialNumber: serialNumber});
    if (existing) {
        return true;
    }
    return false;
}

const addMeter = async (data) => {
    // Check existing serial number
    const existing = await db.meters.get({serialNumber: data.serialNumber});
    if (existing) {
        await db.meters.where('serialNumber').equals(data.serialNumber).modify(data);
    }
    else {
        return await db.meters.put(data);
    }
}

const updateMeter = async (serialNumber, data) => {
    await db.meters.where('serialNumber').equals(serialNumber).modify(data);
}

const deleteMeter = async (data) => {
    await db.meters.where('serialNumber').equals(data.serialNumber).delete();
}

const purgeMeters = async () => {
    return await db.meters.clear();
}


export {
    loadSettings,
    loadSetting,
    deleteSetting,
    saveSetting,
    saveSettings,
    addMeter,
    updateMeter,
    deleteMeter,
    meterExistsBySerial,
    loadMeters,
    purgeMeters,
};
import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import {
    loadSettings as idbLoadSettings,
    saveSettings as idbSaveSettings,
    saveSetting as idbSaveSetting,
    addMeter as idbAddMeter,
    updateMeter as idbUpdateMeter,
    meterExistsBySerial as idbMeterExistsBySerial,
    loadMeters as idbLoadMeters,
    purgeMeters as idbPurgeMeters,
} from '../idb';

export const loadSettings = () => {
    return async (dispatch) => {
        const settings = await idbLoadSettings();

        if (!settings.AppInstallationId) {
            // No AppInstallationId means new installation: Generate id and save it
            settings.AppInstallationId = uuidv4();
            await idbSaveSettings(settings);
        }

        if (settings.displayIntro === undefined) {
            settings.displayIntro = true;
        }

        dispatch({ type: 'UPDATE_SETTINGS', payload: settings });
    }
}

export const saveSettings = (data) => {
    return async (dispatch) => {
        await idbSaveSettings(data);
        dispatch({ type: 'UPDATE_SETTINGS', payload: data });
    }
}

export const updateSettings = (data) => {
    return({type: 'UPDATE_SETTINGS', payload: data});
}

export const hideIntro = () => {
    return async (dispatch) => {
        await idbSaveSetting({key: 'displayIntro', value: false});
        const settings = await idbLoadSettings();
        dispatch({ type: 'UPDATE_SETTINGS', payload: settings });
    }
}

export const setStatus = (data) => {
    return( { type: 'SET_STATUS', payload: data });
}

export const openSettings = () => {
    return( { type: 'OPEN_SETTINGS' });
}

export const closeSettings = () => {
    return( { type: 'CLOSE_SETTINGS' });
}

export const openScanner = () => {
    return( { type: 'OPEN_SCANNER' });
}

export const closeScanner = () => {
    return( { type: 'CLOSE_SCANNER' });
}

export const cameraIsOn = () => {
    return( { type: 'CAMERA_IS_ON' });
}

export const cameraIsOff = () => {
    return( { type: 'CAMERA_IS_OFF' });
}

export const torchIsOn = () => {
    return( { type: 'TORCH_IS_ON' });
}

export const torchIsOff = () => {
    return( { type: 'TORCH_IS_OFF' });
}

export const openMeterList = () => {
    return( { type: 'OPEN_METER_LIST' });
}

export const closeMeterList = () => {
    return( { type: 'CLOSE_METER_LIST' });
}

export const addMeter = (data) => {
    return async (dispatch) => {
        var meters;
        if (await idbMeterExistsBySerial(data.serialNumber)) {
            if (window.confirm("Mittari sarjanumerolla " + data.serialNumber + " löytyy jo listalta. Haluatko korvata olemassa olevan mittarin tiedot?")) {
                await idbAddMeter(data);
                dispatch({ type: 'UPDATE_SETTINGS', payload: {selectedMeterType: null, meterSerialNumber: null, apartment: null} });
                dispatch({ type: 'DISPLAY_SUCCESS_MESSAGE', payload: 'Mittarin tiedot päivitetty' });
            }
        }
        else {
            // Check duplicate apartment - meter type
            var dFound = false;
            meters = await idbLoadMeters();
            for(let i in meters) {
                if (meters[i].apartment === data.apartment && meters[i].meterType === data.meterType) {
                    dFound = true;
                    break;
                }
            }
            if (dFound) {
                var t;
                if (data.meterType === 'cold') {
                    t = 'kylmävesi';
                }
                if (data.meterType === 'warm') {
                    t = 'lämminvesi';
                }
                dispatch({ type: 'OPEN_METER_UPDATE_MODAL' });
                return;
            }

            await idbAddMeter(data);
            dispatch({ type: 'UPDATE_SETTINGS', payload: {selectedMeterType: null, meterSerialNumber: null, apartment: null} });
            dispatch({ type: 'DISPLAY_SUCCESS_MESSAGE', payload: 'Mittari lisätty' });
        }
        meters = await idbLoadMeters();
        dispatch({ type: 'UPDATE_METERS', payload: meters });

        setTimeout(() => {
            dispatch({ type: 'HIDE_SUCCESS_MESSAGE' });
        }, 2000);

    }
}

export const updateMeterByApartmentAndType = (data) => {
    return async (dispatch) => {
        const meters = await idbLoadMeters();
        // Get meter with apartment and type
        var m;
        for (let i in meters) {
            if (meters[i].apartment === data.apartment && meters[i].meterType === data.meterType) {
                m = meters[i];
                break;
            }
        }
        if (m) {
            await idbUpdateMeter(m.serialNumber, data);
            dispatch({ type: 'CLOSE_METER_UPDATE_MODAL' });
            dispatch({ type: 'UPDATE_SETTINGS', payload: {selectedMeterType: null, meterSerialNumber: null, apartment: null} });
            const newMeters = await idbLoadMeters();
            dispatch({ type: 'UPDATE_METERS', payload: newMeters });
            dispatch({ type: 'DISPLAY_SUCCESS_MESSAGE', payload: 'Mittarin tiedot päivitetty' });
            setTimeout(() => {
                dispatch({ type: 'HIDE_SUCCESS_MESSAGE' });
            }, 2000);
        }
        else {
            console.log("No meter found");
        }
    }
}

export const closeMeterUpdateModal = () => {
    return({ type: 'CLOSE_METER_UPDATE_MODAL' });
}

export const loadMeters = () => {
    return async (dispatch) => {
        const meters = await idbLoadMeters();
        dispatch({ type: 'UPDATE_METERS', payload: meters });
    }
}

export const sendMeterList = (data) => {
    return async (dispatch) => {
        dispatch({ type: 'SET_STATUS', payload: {sendingList: true} });
        try {
            var meters = await idbLoadMeters();
            var settings = await idbLoadSettings();
            await api.post('/api/send/', {appInstallationId: settings.AppInstallationId, companyName: settings.companyName, emailAddress: data.emailAddress, meters: data.meters});
            await idbPurgeMeters();
            meters = await idbLoadMeters();
            dispatch({ type: 'UPDATE_METERS', payload: meters });
            dispatch({ type: 'CLOSE_METER_LIST' });
            dispatch({ type: 'SET_STATUS', payload: {sendingList: false } });
            setTimeout(() => {
                dispatch({ type: 'HIDE_SUCCESS_MESSAGE' });
            }, 3000);
            dispatch({ type: 'DISPLAY_SUCCESS_MESSAGE', payload: 'Mittarilista lähetetty' });
        } catch (error) {
            dispatch({ type: 'SET_STATUS', payload: {sendingList: false } });
            alert(error);
        }
    }
}

export const displaySuccessMessage = (message) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch({ type: 'HIDE_SUCCESS_MESSAGE' });
        }, 3000);
        dispatch({ type: 'DISPLAY_SUCCESS_MESSAGE', payload: message });
    }
}
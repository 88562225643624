import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from "mdbreact";
import { MDBModal, MDBModalBody } from "mdbreact";
import { closeScanner, setStatus, updateSettings, cameraIsOn, cameraIsOff, torchIsOn, torchIsOff } from '../actions'
import { isIOS } from 'react-device-detect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faBell, faPowerOff, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

// import { BrowserMultiFormatReader } from '@zxing/library';
import { BrowserBarcodeReader } from '@zxing/library';

// const codeReader = new BrowserQRCodeReader();
const codeReader = new BrowserBarcodeReader();

class Scanner extends React.Component {

    state = {};

    componentDidMount = () => {
        this.audio = new Audio('/media/ding2.mp3');
    }

    close = () => {
        codeReader.reset();
        this.props.closeScanner();
        // this.setState({hLineVisible: false});
    }

    serialNumberFound = (serialNumber) => {
        this.ding();
        this.props.updateSettings({meterSerialNumber: serialNumber});
        this.close();
    }

    ding = () => {
        this.audio.play();
    }

    torch = () => {
        if (this.props.status.torchIsOn) {
            document.getElementById('cameraElement').srcObject.getVideoTracks()[0].applyConstraints({ advanced: [{ torch: false }] });
            this.props.torchIsOff();
        }
        else {
            const track = document.getElementById('cameraElement').srcObject.getVideoTracks()[0];
            console.log("Capabilities:");
            console.log(track.getCapabilities());
            if (!track.getCapabilities().torch) {
                this.setState({torchWarning: true});
            }
            var result = track.applyConstraints({ advanced: [{ torch: true }] });
            result.then( (p) => {
                this.props.torchIsOn();
            });
            result.catch( (p) => {
                console.log("Torch error");
                console.log(p);
            });

        }
    }

    cameraIsOn = () => {
        if (this.props.status.cameraIsOn) { return; }
        this.props.cameraIsOn();
        // setTimeout(() => { document.getElementById('cameraElement')
        //     .srcObject.getVideoTracks()[0].applyConstraints({ advanced: [{ torch: true }] }); }, 2000);
        // console.log( document.getElementById('cameraElement').srcObject.getVideoTracks()[0] );
    }

    getCapabilities = async () => {
        var stream = document.getElementById('cameraElement').srcObject.getVideoTracks()[0];
        var c = await stream.getCapabilities();
        console.log(c);
        // alert(c);
    }

    render = () => {

        if (this.props.status.scannerOpen && !this.props.status.cameraIsOn) {

            codeReader.decodeFromVideoDevice(undefined, 'cameraElement', (result, err) => {
                if (result) {
                    this.serialNumberFound(result.text);
                }
            });

        }

        return (
            <MDBModal isOpen={this.props.status.scannerOpen} toggle={this.close} size="fluid" className="mt-2 mb-0">
                <MDBModalBody>
                    <h4>Skannaa viivakoodi
                    <i onClick={this.close} style={{ position: 'absolute', right: '1rem', top: '1rem' }} className="fas fa-times-circle text-danger"></i>
                    </h4>
                    <div style={{position: 'relative'}}>
                        { isIOS ? <div style={{ zIndex: '100', position: 'absolute', top: '30%', left: '0', fontSize:'10pt', color: '#9e9e9e'}}>Jos kamera ei käynnisty, saattaa virhe johtua liian vanhasta iOS-versiosta. Ole hyvä ja tarkista, että iOS-versio on vähintään 13 ja päivitä se jos mahdollista.</div> : null}
                        { this.props.status.cameraIsOn ?
                        <div className={'w-100'} style={{position: 'absolute', zIndex: '300', top: '50%', height: '1px', backgroundColor: '#ff0000', boxShadow: '0 0 5px #ff0000' }} />
                        :
                        null }
                        <video style={{position: 'relative', zIndex: '200'}} id="cameraElement" width="100%" onPlay={this.cameraIsOn}>
                        </video>
                        <MDBBtn className="btn-sm ml-0" color="secondary" onClick={this.torch}>
                            { this.props.status.torchIsOn ? <FontAwesomeIcon icon={faPowerOff} size="lg" /> : <FontAwesomeIcon icon={faLightbulb} size="lg" /> }
                        </MDBBtn>
                        { this.state.torchWarning ? <span style={{fontSize:'10pt', color: '#9e9e9e'}}><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>Laitteesi ei tue taskulamppua</span> : null}
                        {/* <MDBBtn className="btn-sm ml-0" color="secondary" onClick={this.ding}>
                            <FontAwesomeIcon icon={faBell} size="lg" />
                        </MDBBtn> */}
                    </div>
                </MDBModalBody>
            </MDBModal>
        );

    }

}

function mapStateToProps(state) {
    return {
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        closeScanner,
        updateSettings,
        setStatus,
        cameraIsOn,
        torchIsOn,
        torchIsOff,
    }
)(Scanner);

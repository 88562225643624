import React from 'react';
import { browserName, osName, isIOS, isAndroid, isChrome, isFirefox, isSafari } from 'react-device-detect';

class InstallInstructions extends React.Component {

    componentDidMount = () => {
        this.instructionsFound = false;
    }

    renderBrowserInfo = () => {
        return (
            <span className="ml-3" style={{ fontSize: '10pt'}}>
                {osName} ({browserName})
            </span>
        )
    }

    renderIOS = () => {
        if (!isIOS) { return '' }
        this.instructionsFound = true;
        if (isSafari) {
            return(
                <div>
                    <h5>iPhone (Safari)</h5>
                    <p>
                        Paina keskellä alareunassa olevaa <img src="/img/icon_apple_share.svg" alt="" style={{width:'24px'}} />-symbolia ja paina valikosta kohtaa "Lisää Koti-valikkoon" ("Add to home screen").
                        Tämän jälkeen sovellus löytyy puhelimesta muiden applikaatioiden joukosta.
                    </p>
                    <p className="font-italic">
                        Jos tämä ilmoitus ilmestyy vielä avatessasi sovelluksen puhelimen työpöydältä, poista sovellus ja asenna se uudelleen.
                    </p>
                </div>
            )
        }
        else {
            return(
                <div>
                    <h5>iPhone</h5>
                    <p>Sovelluksen asennus vaatii sivun avaamisen Safari-selaimella.</p>
                </div>
            )
        }
    }

    renderAndroidChrome = () => {
        if (!isAndroid) { return '' }
        if (!isChrome) { return '' }
        this.instructionsFound = true;
        return (
            <div>
                <h5>Android (Chrome)</h5>
                <p>
                    Paina oikeassa yläkulmassa olevaa  <i className="fas fa-ellipsis-v"></i> -symbolia ja paina valikosta kohtaa "Lisää aloitusnäyttöön" ("Add to home screen").
                    Tämän jälkeen sovellus löytyy puhelimen aloitusnäytöstä tai sovellusvalikosta.
                </p>
            </div>
        )
    }

    renderAndroidFirefox = () => {
        if (!isAndroid) { return '' }
        if (!isFirefox) { return '' }
        this.instructionsFound = true;
        return(
            <div>
                <h5>Android (Firefox)</h5>
                <p>Kaikki sovelluksen ominaisuudet eivät toimi Firefoxilla. Suosittelemme tämän sivun avaamista Chrome-selaimella.</p>
                {/* <p>
                    Paina osoiterivin oikealla puolella olevaa  <i class="fas fa-home"></i> -symbolia ja sen jälkeen "Lisää aloitusnäytölle".
                    Tämän jälkeen sovellus löytyy puhelimen aloitusnäytöstä tai sovellusvalikosta.
                </p> */}
            </div>
        )
    }

    renderNoInstructions = () => {
        return (
            <div>
                Laitteellesi ei löytynyt asennusohjeita.
            </div>
        )
    }

    render = () => {
        return (
            <div className="col-12 p-1">
                <div className="card blue lighten-4 p-2">
                    <h4>Asennusohjeet {this.renderBrowserInfo()}</h4>
                    {this.renderIOS()}
                    {this.renderAndroidChrome()}
                    {this.renderAndroidFirefox()}
                    {!this.instructionsFound ? this.renderNoInstructions() : null }
                </div>
            </div>
        )
    }
}

export default InstallInstructions;

import React from 'react';
import { connect } from 'react-redux';
// import { MDBModal, MDBModalBody } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { openSettings, closeSettings } from '../actions';
import hydrolinkLogo from '../img/hydrolink.png';

class TopBar extends React.Component {

    state = {
        modal: false,
        image: undefined
    }

    toggleSettings = () => {
        this.setState({
            settings: !this.state.settings
        });
    }

    render = () => {
        return (
            <div className="text-center">
                <img src={hydrolinkLogo} alt="" className="my-2" style={{maxWidth: '150px'}} />
                <FontAwesomeIcon style={{position:'absolute', right: '.25em', top: '.2em', color: 'rgba(0,0,0,0.25)'}} icon={faCog} size="2x" onClick={this.props.openSettings} />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        openSettings,
        closeSettings,
    }
)(TopBar);

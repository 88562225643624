import React from 'react';
import { connect } from 'react-redux';
import { MDBCard } from "mdbreact";
import { MDBBtn } from "mdbreact";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { hideIntro } from '../actions'
import { updateSettings, saveSettings, setStatus } from '../actions'

import { Route } from 'react-router-dom'

// import api from '../api';

class GeneralSettings extends React.Component {

    componentDidMount = () => {
        if (this.props.settings.resetCompanyName) {
            this.props.saveSettings({companyName: '', resetCompanyName: undefined});
        }
    }

    continue = (history) => {
        this.props.saveSettings(this.props.settings);

        if (!this.props.settings.companyName) {
            this.props.setStatus({companyMissing: true});
            return;
        }

        if (!this.props.settings.userEmailAddress || this.props.settings.userEmailAddress !== this.props.settings.userEmailAddress2) {
            this.props.setStatus({invalidEmailAddress: true});
            return;
        }
        if (!this.props.settings.userEmailAddress.includes('@') || !this.props.settings.userEmailAddress.includes('.')) {
            this.props.setStatus({invalidEmailAddress: true});
            return;
        }

        // Purge errors if found
        if (this.props.status.invalidEmailAddress) {
            this.props.setStatus({invalidEmailAddress: undefined});
        }
        if (this.props.status.companyMissing) {
            this.props.setStatus({companyMissing: undefined});
        }
        history.push('/installed/meter/');
    }

    updateSetting = (obj) => {
        this.props.updateSettings(obj);
    }

    companyNameChanged = (e) => {
        this.props.updateSettings({companyName: e.target.value});
    }

    render = () => {
        return (
            <div>
                <div className="md-form">
                    <span style={{fontSize:'10pt'}} >Taloyhtiön nimi</span><br/>
                    {this.props.status.companyMissing ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Pakollinen tieto</span> : null}
                    <input type="text" className="form-control pt-0" value={this.props.settings.companyName || ''} onChange={ this.companyNameChanged } placeholder="As Oy Taloyhtiö" />

                    <span style={{fontSize:'10pt'}}>Sähköpostiosoitteesi</span><br/>
                    {this.props.status.invalidEmailAddress ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Tarkista sähköpostiosoite</span> : null}
                    <input type="text" className="form-control pt-0" defaultValue={this.props.settings.userEmailAddress || ''} onChange={ (e) => { this.props.settings.userEmailAddress = e.target.value; } } />
                    {/* <input type="text" className="form-control pt-0" defaultValue={this.props.settings.userEmailAddress || ''} onChange={ (e) => { this.updateSetting({'userEmailAddress': e.target.value}) } } /> */}

                    <span className="d-block" style={{fontSize:'10pt'}}>Sähköpostiosoitteesi uudelleen</span>
                    {this.props.status.invalidEmailAddress ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Tarkista sähköpostiosoite</span> : null}
                    <input type="text" className="form-control pt-0" defaultValue={this.props.settings.userEmailAddress2 || ''} onChange={ (e) => { this.props.settings.userEmailAddress2 = e.target.value; } } />
                    {/* <input type="text" className="form-control pt-0" defaultValue={this.props.settings.userEmailAddress2 || ''} onChange={ (e) => { this.updateSetting({'userEmailAddress2': e.target.value}) } } /> */}

                    <MDBCard className="blue lighten-4 mb-3 p-2 mt-3">
                        Huom! Varmistathan, että sähköpostiosoitteesi on oikein. Luettelon muodostamisen jälkeen järjestelmä lähettää luettelon sähköpostiosoitteeseesi sekä laitetoimittajalle.
                    </MDBCard>

                    <Route render={({ history }) => (
                        <MDBBtn className="w-100 m-0" color="primary" onClick={() => { this.continue(history) } }>
                            Jatka
                            <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                        </MDBBtn>
                    )} />
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        hideIntro,
        updateSettings,
        saveSettings,
        setStatus,
    }
)(GeneralSettings);

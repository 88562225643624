import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from "mdbreact";
import { MDBCard } from "mdbreact";
import { MDBModal, MDBModalBody } from "mdbreact";
import { closeMeterList, sendMeterList } from '../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class MeterList extends React.Component {

    state = {};

    send = () => {
        this.props.sendMeterList({emailAddress: this.props.settings.userEmailAddress, meters: this.props.meters});
    }

    render = () => {

        return (
            <MDBModal isOpen={this.props.status.meterListOpen} toggle={this.props.closeMeterList} size="fluid" className="mt-2 mb-0">
                <MDBModalBody>
                    <h4>Lähetä mittariluettelo
                    <i onClick={this.props.closeMeterList} style={{ position: 'absolute', right: '1rem', top: '1rem' }} className="fas fa-times-circle text-danger"></i>
                    </h4>
                    <MDBCard className="blue lighten-4 mb-3 p-2 mt-3">
                        Tarkista onko kaikkien huoneistojen ja mahdollisten muiden tilojen mittarit luettelossa.
                    </MDBCard>

                    <div className="row">
                        <div className="col-4">
                            <span className="d-block" style={{fontSize:'10pt'}}>Kylmä</span>
                            <h5>{this.props.meters.filter(meter => meter.meterType === 'cold').length}</h5>
                        </div>
                        <div className="col-4">
                        <span className="d-block" style={{fontSize:'10pt'}}>Lämmin</span>
                            <h5>{this.props.meters.filter(meter => meter.meterType === 'warm').length}</h5>
                        </div>
                        <div className="col-4">
                        <span className="d-block" style={{fontSize:'10pt'}}>Yhteensä</span>
                            <h5>{this.props.meters.length}</h5>
                        </div>
                    </div>

                    <MDBBtn className="w-100 mx-0 px-1 mt-2" color="primary" onClick={this.send}>
                        <FontAwesomeIcon className="mx-2" icon={faEnvelope} />
                        {this.props.status.sendingList ? 'Lähetetään ...' : 'Lähetä'}
                    </MDBBtn>

                    <table className="w-100 mt-2">
                        <thead>
                            <tr>
                                <th style={{width: '40%'}}>Huoneisto</th>
                                <th style={{width: '50%'}}>Mittari</th>
                                <th style={{width: '10%'}}>KV/LV</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.meters.map( (item, i) => {
                                    return(
                                        <tr key={i}>
                                            <td>{item.apartment}</td>
                                            <td>{item.serialNumber}</td>
                                            <td className="text-right">
                                                {item.meterType === 'cold' ? <FontAwesomeIcon className="text-primary" icon={faCircle} /> : null }
                                                {item.meterType === 'warm' ? <FontAwesomeIcon className="text-danger" icon={faCircle} /> : null }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </MDBModalBody>
            </MDBModal>
        );

    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status,
        meters: state.meters
    };
}
export default connect(
    mapStateToProps,
    {
        closeMeterList,
        sendMeterList
    }
)(MeterList);

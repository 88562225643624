import React from 'react';
import { connect } from 'react-redux';
import { MDBCard } from "mdbreact";
import { MDBBtn } from "mdbreact";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { hideIntro, openScanner, openMeterList, setStatus, updateSettings, saveSettings, addMeter, displaySuccessMessage } from '../actions'
import MeterList from "./MeterList";
import MeterUpdateModal from "./MeterUpdateModal";

import { Route } from 'react-router-dom'

// import api from '../api';

class MeterSettings extends React.Component {

    save = () => {
        var errors = this.getErrors();

        // Validation
        if (!this.props.settings.companyName) {
            // This should normally never happen
            alert("Taloyhtiön nimi puuttuu. Palaa takaisin edelliseen näkymään.");
            return;
        }

        var hasErrors = false;
        if (!this.props.settings.apartment) {
            hasErrors = true;
            errors.apartmentMissing = true;
        }
        if (!this.props.settings.selectedMeterType) {
            hasErrors = true;
            errors.meterTypeMissing = true;
        }
        if (!this.props.settings.meterSerialNumber) {
            hasErrors = true;
            errors.meterSerialNumberMissing = true;
        }
        else {
            var meterSerialNumber = this.props.settings.meterSerialNumber;

            // Serial numbers should be 8 digits long unless they are 7 in which case a leading '0' is added
            if (meterSerialNumber.length === 7) {
                meterSerialNumber = `0${meterSerialNumber}`;
                this.props.updateSettings({meterSerialNumber: meterSerialNumber});
            }
            else {
                if (meterSerialNumber.length !== 8) {
                    hasErrors = true;
                    errors.meterSerialNumberInvalid = true;
                }
            }

        }

        this.props.setStatus(errors);
        if (!hasErrors) {
            this.props.addMeter({serialNumber: meterSerialNumber, apartment: this.props.settings.apartment, meterType: this.props.settings.selectedMeterType});
        }

    }

    getErrors = () => {
        var errors = {
            apartmentMissing: undefined,
            meterTypeMissing: undefined,
            meterSerialNumberMissing: undefined,
            meterSerialNumberInvalid: undefined,
        };
        return errors;
    }

    purgeErrors = () => {
        this.props.setStatus(this.getErrors());
    }

    back = (history) => {
        this.props.updateSettings({selectedMeterType: undefined, apartment: '', meterSerialNumber: ''});
        this.purgeErrors();
        history.push('/installed/general/');
    }

    selectCold = () => {
        if (this.props.status.meterTypeMissing) {
            this.props.setStatus({meterTypeMissing: undefined});
        }
        this.props.updateSettings({selectedMeterType: 'cold'});
    }

    selectWarm = () => {
        if (this.props.status.meterTypeMissing) {
            this.props.setStatus({meterTypeMissing: undefined});
        }
        this.props.updateSettings({selectedMeterType: 'warm'});
    }

    serialNumberChanged = (e) => {
        if (this.props.status.meterSerialNumberMissing && e.target.value) {
            this.props.setStatus({meterSerialNumberMissing: undefined});
        }
        if (this.props.status.meterSerialNumberInvalid && e.target.value) {
            this.props.setStatus({meterSerialNumberInvalid: undefined});
        }
        this.props.updateSettings({meterSerialNumber: e.target.value});
    }

    apartmentChanged = (e) => {
        if (this.props.status.apartmentMissing && e.target.value) {
            this.props.setStatus({apartmentMissing: undefined});
        }
        this.props.updateSettings({apartment: e.target.value});
    }

    render = () => {

        return (
            <div>
                <span style={{fontSize:'10pt'}}>Taloyhtiön nimi</span><br/>
                <h5>{this.props.settings.companyName}</h5>
                <div className="md-form mt-1">
                    <span style={{fontSize:'10pt'}}>Huoneiston numero</span><br/>
                    {this.props.status.apartmentMissing ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Pakollinen tieto</span> : null}
                    <input type="text" className="form-control pt-0" value={this.props.settings.apartment || ''} onChange={this.apartmentChanged} placeholder="A1" />

                    <span style={{fontSize:'10pt'}}>Valitse mittarin tyyppi</span><br/>
                    {this.props.status.meterTypeMissing ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Valitse mittarin tyyppi</span> : null}
                    <div className="text-center">
                        <div className={'m-2 d-inline-block position-relative ' + (this.props.settings.selectedMeterType === 'warm' ? 'grey lighten-1' : 'primary-color')} style={{width:'80px', height: '80px', lineHeight: '80px', borderRadius: '40px'}} onClick={this.selectCold}>
                            {this.props.settings.selectedMeterType === 'cold' ?
                            <FontAwesomeIcon className="mr-2 text-light" size="4x" icon={faCheck} style={{position: 'absolute', left: '7px', top: '10px', opacity: '.6'}} />
                            : null}
                            <span className="position-relative ml-1" style={{textShadow: '0 0 10px rgba(0,0,0,0.5)', color: '#ffffff', letterSpacing: '1px'}} >KYLMÄ</span>
                        </div>
                        <div className={'m-2 d-inline-block position-relative ' + (this.props.settings.selectedMeterType === 'cold' ? 'grey lighten-1' : 'danger-color')} style={{width:'80px', height: '80px', lineHeight: '80px', borderRadius: '40px'}} onClick={this.selectWarm}>
                            {this.props.settings.selectedMeterType === 'warm' ?
                            <FontAwesomeIcon className="mr-2 text-light" size="4x" icon={faCheck} style={{position: 'absolute', left: '7px', top: '10px', opacity: '.6'}} />
                            : null}
                            <span className=" position-relative ml-1" style={{textShadow: '0 0 10px rgba(0,0,0,0.5)', color: '#ffffff', letterSpacing: '1px'}} >LÄMMIN</span>
                        </div>
                    </div>

                    <span style={{fontSize:'10pt'}}>Mittarin sarjanumero</span><br/>
                    {this.props.status.meterSerialNumberMissing ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Pakollinen tieto</span> : null}
                    {this.props.status.meterSerialNumberInvalid ? <span className="d-block text-danger"><FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />Sarjanumeron tulee olla 8 merkkiä pitkä</span> : null}
                    <input type="text" className="form-control pt-0 mb-0" value={this.props.settings.meterSerialNumber || ''} onChange={ this.serialNumberChanged } placeholder="12345678" />
                    <span className="d-block mb-2" style={{fontSize:'10pt', color: '#bdbdbd'}}>Skannaa tai kirjoita</span>

                    <MDBBtn className="w-100 m-0 mb-3 px-1" color="default" onClick={this.props.openScanner}>
                        Skannaa viivakoodi
                    </MDBBtn>

                    <MDBBtn className="w-100 m-0 px-1" color="primary" onClick={this.save}>
                        <FontAwesomeIcon className="mr-2" icon={faCheck} />
                        Lisää luetteloon
                    </MDBBtn>

                    <Route render={({ history }) => (
                        <div className="row">
                            <div className="col-6 pr-3">
                                <MDBBtn className="w-100 m-0 px-1 mt-3" color="danger" onClick={() => { this.back(history) } }>
                                    <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                                    Takaisin
                                </MDBBtn>
                            </div>
                            <div className="col-6 pl-0">
                                <MDBBtn className="w-100 m-0 mb-3 mt-3 px-1" color="primary" onClick={this.props.openMeterList}>
                                    Lista valmis {this.props.meters.length > 0 ? '(' + this.props.meters.length + ')' : null}
                                </MDBBtn>
                            </div>
                        </div>
                    )} />

                    <MeterList />
                    <MeterUpdateModal />
                    {
                        this.props.status.successMessage ?
                        <MDBCard className="animated fadeInDown text-white text-uppercase success-color text-nowrap px-3 py-2 mx-2" style={{position: 'fixed', top: '5px', left: '0', fontSize: '.81rem'}}>
                            <span> <FontAwesomeIcon className="mr-2" icon={faCheck}/>{this.props.status.successMessage}</span>
                        </MDBCard>
                        :
                        null
                    }
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status,
        meters: state.meters
    };
}
export default connect(
    mapStateToProps,
    {
        hideIntro,
        openScanner,
        openMeterList,
        setStatus,
        updateSettings,
        saveSettings,
        addMeter,
        displaySuccessMessage,
    }
)(MeterSettings);

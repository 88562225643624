import { combineReducers } from 'redux';

function settingsReducer(state = {}, action) {
    if (action.type === 'UPDATE_SETTINGS') {
        return {...state, ...action.payload};
    }
    return state;
}


function statusReducer(state={}, action) {
    if (action.type === 'SET_STATUS') {
        return {...state, ...action.payload};
    }

    if (action.type === 'OPEN_SETTINGS') {
        return {...state, ...{settingsOpen: true}}
    }

    if (action.type === 'CLOSE_SETTINGS') {
        return {...state, ...{settingsOpen: false}}
    }

    if (action.type === 'OPEN_SCANNER') {
        return {...state, ...{scannerOpen: true}}
    }

    if (action.type === 'CLOSE_SCANNER') {
        return {...state, ...{scannerOpen: false, cameraIsOn: false, torchIsOn: false}}
    }

    if (action.type === 'CAMERA_IS_ON') {
        return {...state, ...{cameraIsOn: true}}
    }

    if (action.type === 'CAMERA_IS_OFF') {
        return {...state, ...{cameraIsOn: false}}
    }

    if (action.type === 'TORCH_IS_ON') {
        return {...state, ...{torchIsOn: true}}
    }

    if (action.type === 'TORCH_IS_OFF') {
        return {...state, ...{torchIsOn: false}}
    }

    if (action.type === 'OPEN_METER_LIST') {
        return {...state, ...{meterListOpen: true}}
    }

    if (action.type === 'CLOSE_METER_LIST') {
        return {...state, ...{meterListOpen: false}}
    }

    if (action.type === 'DISPLAY_SUCCESS_MESSAGE') {
        return {...state, ...{successMessage: action.payload}}
    }

    if (action.type === 'HIDE_SUCCESS_MESSAGE') {
        return {...state, ...{successMessage: undefined}}
    }

    if (action.type === 'OPEN_METER_UPDATE_MODAL') {
        return {...state, ...{meterUpdateModalVisible: true}}
    }

    if (action.type === 'CLOSE_METER_UPDATE_MODAL') {
        return {...state, ...{meterUpdateModalVisible: false}}
    }

    return state;
}

function metersReducer(state=[], action) {
    if (action.type === 'UPDATE_METERS') {
        return action.payload;
    }
    return state;
}

export default combineReducers({
    settings: settingsReducer,
    status: statusReducer,
    meters: metersReducer,
});
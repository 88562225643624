import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from "mdbreact";
import { MDBCard } from "mdbreact";
import { MDBModal, MDBModalBody } from "mdbreact";
import { closeMeterUpdateModal, updateMeterByApartmentAndType } from '../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class MeterUpdateModal extends React.Component {

    state = {};

    updateMeterByApartmentAndType = () => {
        var data = {
            apartment: this.props.settings.apartment,
            meterType: this.props.settings.selectedMeterType,
            serialNumber: this.props.settings.meterSerialNumber,
        }
        this.props.updateMeterByApartmentAndType(data);
    }

    render = () => {

        return (
            <MDBModal isOpen={this.props.status.meterUpdateModalVisible} toggle={this.props.closeMeterUpdateModal} size="fluid" className="mt-2 mb-0">
                <MDBModalBody>
                    <h4>Meter update
                    <i onClick={this.props.closeMeterUpdateModal} style={{ position: 'absolute', right: '1rem', top: '1rem' }} className="fas fa-times-circle text-danger"></i>
                    </h4>
                    <p>Huoneistossa on jo kylmävesimittari. Korvataanko mittari tai anna lisätunniste.</p>

                    <div className="row">
                        <div className="col-6 pr-1">
                            <MDBBtn className="w-100 px-0 mx-0" color="primary" onClick={this.updateMeterByApartmentAndType}>
                                Korvataan vanha
                            </MDBBtn>
                        </div>
                        <div className="col-6 pl-1">
                            <MDBBtn className="w-100 px-0 mx-0" color="primary" onClick={this.props.closeMeterUpdateModal}>
                                Anna lisätunniste
                            </MDBBtn>
                        </div>
                    </div>

                </MDBModalBody>
            </MDBModal>
        );

    }

}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status,
        // meters: state.meters
    };
}
export default connect(
    mapStateToProps,
    {
        closeMeterUpdateModal,
        updateMeterByApartmentAndType
    }
)(MeterUpdateModal);

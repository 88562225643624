import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from "mdbreact";
import { MDBModal, MDBModalBody } from "mdbreact";
import { loadSettings, saveSettings, closeSettings } from '../actions'

import * as serviceWorker from '../serviceWorker';
// import api from '../api';

class Settings extends React.Component {

    state = {};

    saveSettings = () => {
        this.props.saveSettings( this.props.settings );
    }

    update = async () => {
        serviceWorker.unregister();
        window.location.reload(true);
    }

    render = () => {
        return (
            <MDBModal isOpen={this.props.status.settingsOpen} toggle={this.props.closeSettings} size="fluid" className="mt-2 mb-0">
                <MDBModalBody>
                    <h4>Asetukset
                    <i onClick={this.props.closeSettings} style={{ position: 'absolute', right: '1rem', top: '1rem' }} className="fas fa-times-circle text-danger"></i>
                    </h4>
                    <span>App Installation ID</span><br/>
                    <span className="text-default" style={{fontSize: '10pt', fontWeight: 'bold'}}>{this.props.settings.AppInstallationId}</span><br/>
                    {/* <input type="text" className="form-control mb-1" id="input_appInstallationId"
                        defaultValue={this.props.settings.AppInstallationId || ''}
                        onChange={ (e) => { this.props.settings.AppInstallationId = e.target.value; } }
                        placeholder="(empty)"
                    /> */}
                    {/* <MDBBtn className="btn-sm ml-0" color="primary" onClick={this.saveSettings}>Tallenna</MDBBtn> */}
                    <br/>
                    <br/>
                    Versio: <span className="text-default" style={{fontSize: '10pt', fontWeight: 'bold'}}>{window.APP_VERSION}</span> <br/>
                    {/* <MDBBtn className="btn-sm ml-0" color="secondary" onClick={ this.update }>Empty cache</MDBBtn> */}
                    <MDBBtn className="btn-sm ml-0" color="secondary" onClick={this.update}>Päivitä sovellus</MDBBtn>
                    <MDBBtn className="btn-sm mr-0 float-right" color="danger" onClick={this.props.closeSettings}>Sulje</MDBBtn>
                </MDBModalBody>
            </MDBModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings,
        status: state.status
    };
}
export default connect(
    mapStateToProps,
    {
        loadSettings,
        saveSettings,
        closeSettings,
    }
)(Settings);

import React from 'react';
import { connect } from 'react-redux';
import { loadSettings, loadMeters, updateSettings, saveSettings } from '../actions'

class DataLoader extends React.Component {

    componentDidMount = () => {
        this.props.loadSettings();
        this.props.loadMeters();
        this.props.saveSettings({selectedMeterType: undefined, apartment: '', resetCompanyName: true});
    }

    render = () => {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings
    };
}
export default connect(
    mapStateToProps,
    {
        loadSettings,
        loadMeters,
        updateSettings,
        saveSettings,
    }
)(DataLoader);

import axios from 'axios';

// var baseURL = 'https://x-pakka.trineria.fi/';
var baseURL = '/';
if (window.location.host.includes("localhost")) {
    baseURL = 'http://localhost:9005/';
}

export default axios.create({
    baseURL: baseURL
});